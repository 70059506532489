import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {slugify} from "../../utils";

import './ExpenseList.scss'
import {numberWithCommas} from "../../utils/numberToComma";
import {GatsbyImage, getImage} from "gatsby-plugin-image";


export const ExpenseList = ({ bgImage, className, days, destination, expenses, nights}) => {

    const classes = classNames(
        'checklist',
        className
    );

    const calculateTotal = () => (
        numberWithCommas(expenses.reduce((accum,item) => accum + Number(item.cost), 0))
    )

    return (
        <div className={classes}>
            <GatsbyImage className='bg-image' alt={`${slugify(destination)} Image`} image={getImage(bgImage.gatsbyImageData)}/>
            <div className='content'>
                <div className='list-container'>
                    <h2 className='destination'>{destination}</h2>
                    <p className='length'>{nights} Nights, {days} Days</p>
                    <ul className='expense-list'>
                        {expenses.map( ({name, cost}, index) => (
                            <li className='item' key={`${slugify(destination)}-expense-item-${index}`}>{name} <span className='pull-right'>${cost}</span></li>
                        ))}
                        <li className='total-expense'>Total <span className='pull-right'>${calculateTotal()}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
};

ExpenseList.defaultProps = {
};

ExpenseList.propTypes = {
    days: PropTypes.number.isRequired,
    destination: PropTypes.string.isRequired,
    expenses: PropTypes.array.isRequired,
    nights: PropTypes.number.isRequired,
    bgImage: PropTypes.object,
    className: PropTypes.string,
};

export default ExpenseList;