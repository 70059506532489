import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {Button, SectionContainer, SimpleRow} from "../../primitives";

import './CallToAction.scss'

export const CallToAction = ({ bgColor, bgImage, btnText, className, title }) => {

    const classes = classNames(
        'call-to-action',
        className
    );

    return (
        <SectionContainer className={classes} bgColor={bgColor} bgImage={bgImage}>
            <div className='overlay'>
                <SimpleRow>
                    <div className=''>
                        <h2 className='title'>{title}</h2>
                        <Button label={btnText} href='/contact' tag='a'/>
                    </div>
                    <div/>
                </SimpleRow>
            </div>
        </SectionContainer>
    )
};

CallToAction.defaultProps = {
    bgColor: '#F8FAFF'
}

CallToAction.propTypes = {
    btnText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    bgColor: PropTypes.string,
    bgImage: PropTypes.object,
    className: PropTypes.string,
};

export default CallToAction;
