import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {slugify, useElementHeight, useMobileBool, useTransformHTML} from "../../utils";

import './HighlightSection.scss'

export const HighlightSection = ({ bgColor, className, ctaBG, days, description, destination, featureImage, images, nights, reverse}) => {

    const classes = classNames(
        'box-wrapper',
        reverse && 'reverse',
        className
    );

    let [imgPosition, setImagePosition] = useState(0)
    const descriptionHTML = useTransformHTML(description?.raw) ?? '';
    const image = getImage(featureImage.gatsbyImageData);

    const moveImage = (length) => {
        const maxPosition = length + 1;
        if(maxPosition === -imgPosition) {
            setImagePosition( -2)
        } else {
            setImagePosition( imgPosition - 1)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => moveImage(images.length), 5000)
        return () => clearTimeout(timer)
    })

    const isMobile = useMobileBool();
    const elementId = `${destination}-text-details`;
    const height = useElementHeight(elementId)
    const boxHeight = isMobile ? 'unset' : `calc(${height}px + 84px)`;
    const imageBackground = isMobile ? bgColor : 'white';

    return (
        <>
        <div className={classes} style={{height: boxHeight, backgroundColor: bgColor}} >
            <div className="box boxa">
                <h2 className='destination'>{destination}</h2>
            </div>
            <div className="box boxb">
                <div className='text-details' id={`${destination}-text-details`}>
                    <div
                        dangerouslySetInnerHTML={{
                        __html: descriptionHTML,
                        }}
                    />
                </div>
            </div>
            <div className="box boxc" style={{backgroundColor: imageBackground}}>
                <div className='image-box' id={`${destination}-image-box`}>
                    <GatsbyImage image={image} alt={'view of mountains'} />
                </div>
            </div>
        </div>
        <a href='/contact' className="cta-highlight-section" style={{backgroundColor: ctaBG}}>
            <span  className='cta-text'>Plan A Trip To {destination}</span>
        </a>

        <div className='highlight-section'>
        <div className='image-row'>
            <div className='image-carousel-row'>

                {images.map( ({gatsbyImageData}, index) => {
                    const position = isMobile ? 175 : 380;
                    const maxLength = images.length;
                    const goBack = index + imgPosition < -1;
                    let imagePosition = ((index + imgPosition) * position) - 110;
                    let style = reverse ? {right: imagePosition} : {left: imagePosition}

                    if(goBack) {
                        const newIndex = maxLength + index;
                        imagePosition = ((newIndex + imgPosition) * position) - 110;
                        const hide = index + imgPosition === -2;
                        style = reverse ? {right: imagePosition,  display: hide ? 'none' : 'block'} : {left: imagePosition, display: hide ? 'none' : 'block'}
                    }

                    return (
                        <div key={`${slugify(destination)}-image-${index}`} className='secondary-image' style={style}>
                            <GatsbyImage alt={'test'} image={getImage(gatsbyImageData)} className='gatsby-image-box' />
                        </div>
                    )
                })}
                {/*<div className='arrow-box'>*/}
                {/*    <div className={`arrow-test left`}>*/}
                {/*        <div className='arrow-container' onClick={() => moveImage('left')}>*/}
                {/*            <ArrowLeft className='left'/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className='arrow-test right'>*/}
                {/*        <div className='arrow-container' onClick={() => moveImage('right')}>*/}
                {/*            <ArrowRight className='right'/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
            </div>
        </>
    )
};

HighlightSection.defaultProps = {
    bgColor: 'transparent',
    images: [],
    reverse: false,
    ctaBG: '#387d98'
};

HighlightSection.propTypes = {
    description: PropTypes.object.isRequired,
    destination: PropTypes.string.isRequired,
    featureImage: PropTypes.object.isRequired,
    images: PropTypes.array.isRequired,
    bgColor: PropTypes.string,
    ctaBG: PropTypes.string,
    className: PropTypes.string,
    reverse: PropTypes.bool
};

export default HighlightSection;