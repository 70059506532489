import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SimpleRow } from "../../primitives";

import './FeatureRow.scss'
import {GatsbyImage, getImage} from "gatsby-plugin-image";


export const FeatureRow = ({ alt, className, circle, description, header, image, reverse, textAlign }) => {

    const classes = classNames(
        'feature-row',
        `text-${textAlign}`,
        className
    );

    const renderImage = () => {
        if(circle) {
            return <>
                <GatsbyImage image={getImage(image)} alt={`${alt}`} className={'feature-image circle'} />
            </>
        } else {
            return <GatsbyImage image={getImage(image)} alt={`${alt}`} className={'feature-row-image'} />
        }
    }

    return (
        <SimpleRow className={classes} reverse={reverse}>
            <div className='content'>
                <h2 className='header'>{header}</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
            </div>
            <div className='image'>
                {renderImage()}
            </div>
        </SimpleRow>
    )
};

FeatureRow.defaultProps = {
    reverse: false
};

FeatureRow.propTypes = {
    alt: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    className: PropTypes.string,
    reverse: PropTypes.bool,
};

export default FeatureRow;