import React from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'

import Layout from '../components/layout'
import Seo from "../components/seo";

import {BlogCard} from "../components/composites";

import './blog.scss'
import {getImage} from "gatsby-plugin-image";
import {slugify} from "../components/utils";

const BlogLandingPage = ({ data, location }) => {
    const {
       allContentfulBlogPost
    } = data

    const posts = allContentfulBlogPost.nodes;

    return (
        <Layout className='blog-landing'>
            <Seo title='Blog'/>
            <div className='blog-grid'>
                {posts.map( ({description, heroImage, slug, title, topics}) => (
                    <BlogCard key={`${slugify(title)}-blog-card`} title={title} preview={description.description} image={getImage(heroImage.gatsbyImageData)} slug={slug} topics={topics} />
                ))}
            </div>
        </Layout>
    )
}

BlogLandingPage.propTypes = {
    data: PropTypes.shape({
        allContentfulMainPage: PropTypes.object
    }),
    location: PropTypes.object
}

export const BlogPostQuery = graphql`
  query {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
        nodes {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          description {
            description
          }
          body {
            childMarkdownRemark {
                html
          }
        }
        heroImage {
          gatsbyImageData
        }
        topics {
          name
          slug
        }
      }
    }
  }
`

export default BlogLandingPage
