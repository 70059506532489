import React, {useRef} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import PaintBanner from "../../../assets/svg/brush-stroke-banner.inline.svg"

import './VideoHeader.scss'

export const VideoHeader = ({ className, title, url }) => {

    const classes = classNames(
        'video-header',
        className
    );

    const videoRef= useRef();
    const setPlayBack = () => {
        videoRef.current.playbackRate = 0.5;
    };

    return (
        <div className={classes}>
            <video autoPlay muted loop id="myVideo" ref={videoRef} onCanPlay={() => setPlayBack()} playsInline={true} disablePictureInPicture={true} preload="metadata">
                <source src={url} type="video/mp4"/>
            </video>
            <div className='content'>
                <PaintBanner className='paint-banner'/>
                <h1 className='title'>{title}</h1>
            </div>
        </div>
    )
};

VideoHeader.defaultProps = {
};

VideoHeader.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
};

export default VideoHeader;