import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { SectionContainer } from "../../primitives";

import './FeaturedMarquee.scss'

export const FeaturedMarquee = ({ alt, className, description, header, image }) => {

    const classes = classNames(
        'featured-marquee',
        className
    );

    return (
        <SectionContainer className={classes} bgColor='#082d5e'>
            <div className='image-container'>
                <img src={image} alt={alt}/>
            </div>
        </SectionContainer>
    )
};

FeaturedMarquee.defaultProps = {};

FeaturedMarquee.propTypes = {
    alt: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default FeaturedMarquee;