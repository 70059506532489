import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {GatsbyImage, getImage} from "gatsby-plugin-image";

import {InputWrapper} from "../../primitives";
import {slugify} from "../../utils";

import './FancyCheckboxes.scss'

export const FancyCheckboxes = (props) => {
    const {
        className,
        helperText,
        label,
        required,
        secondaryLabel,
        secondaryLink,
        secondaryTabIndex,
        show,
        optionArray
    } = props;

    const fromClasses = classNames(
        'checkbox-button-group',
        className
    )

    const renderIcon = (icon, title) => {
        return icon && <GatsbyImage className='checkbox-icon' imgClassName='checkbox-icon-img' alt={title} image={getImage(icon.gatsbyImageData)}/>
    }


    return (
        <>
            {show &&
            <InputWrapper formClass={fromClasses} helperText={helperText} label={label} required={required} secondaryLabel={secondaryLabel} secondaryLink={secondaryLink} secondaryTabIndex={secondaryTabIndex} secondaryMessage='(Click All That Apply)' showSecondaryMessage>
                <div className='shrinker'>
                    {optionArray.map( ({groupName, icon, id, value}) => (
                        <div className='checkbox' htmlFor={id} key={`${slugify(groupName)}-${slugify(value)}-radio-button`}>
                            <input className='checkbox-input' type="checkbox" id={id} name={groupName} value={value}/>
                            <label className='checkbox-label' htmlFor={id}>
                                {renderIcon(icon, value)} {value}
                            </label>
                        </div>
                    ))}
                </div>
            </InputWrapper>
            }
        </>
    )
}

FancyCheckboxes.propTypes = {
    optionArray: PropTypes.array.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    helperText: PropTypes.string,
    secondaryLabel: PropTypes.string,
    secondaryLink: PropTypes.string,
    secondaryTabIndex: PropTypes.number,
    show: PropTypes.bool,
}

FancyCheckboxes.defaultProps = {
    optionArray: [],
    disabled: false,
    required: false,
    show: true
}

export default FancyCheckboxes