import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {SectionContainer} from "../../primitives";
import {FeatureRow} from "../FeatureRow/FeatureRow";

import './AboutMe.scss'

export const AboutMe = ({ description, className, image, title }) => {

    const classes = classNames(
        'about-me-section',
        className
    );

    return (
        <SectionContainer className={classes} bgColor='white'>
            <FeatureRow
                alt={''}
                header={title}
                description={description}
                image={image}
                reverse
                circle
                textAlign='left'
            />
        </SectionContainer>
    )
};

AboutMe.defaultProps = {
    bgColor: '#F8FAFF'
}

AboutMe.propTypes = {
    bgColor: PropTypes.string,
    className: PropTypes.string
};

export default AboutMe;
